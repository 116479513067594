const Link = ({ link,strokeWidth,stroke,nodeCount }) => {
    return (
        <path
            data-level={link.target.data.id} 
            d={`M${link.source.y},${link.source.x}C${(link.source.y + link.target.y) / 2},${link.source.x} ${(link.source.y + link.target.y) / 2},${link.target.x} ${link.target.y},${link.target.x}`}
            fill="none"
            //d={pathFunc(link)}
            stroke={stroke}
            strokeWidth={strokeWidth}
            //className={link?.target?.data?.flag ? "visible" : "hidden"}
            //style={ link?.target?.data?.currentNodes === nodeCount ? { opacity: link?.target?.data?.flag ? 1 : 0, animation: link?.target?.data?.flag ? "fadeIn 0.9s ease-in" : "none" }: {}}
            //className={link?.target?.data?.flag && link?.target?.data?.currentNodes === nodeCount ? "visible" : !link?.target?.data?.flag && link?.target?.data?.currentNodes === nodeCount ? "visibleOut" : link?.target?.data?.flag ? "flagVisible" : "hidden"} // Add CSS classes based on the visibility state}

            // className={link?.target?.data?.flag && link?.target?.data?.currentNodes === nodeCount && link?.target?.data?.depthChange ? "visible visible"+link?.target?.data?.depthChange : !link?.target?.data?.flag && link?.target?.data?.currentNodes === nodeCount ? "visibleOut" : link?.target?.data?.flag ? "flagVisible" : "hidden"} // Add CSS classes based on the visibility state}

            className={link?.target?.data?.flag && link?.target?.data?.currentNodes === nodeCount && link?.target?.data?.depthChange ? "flagVisible visible" : !link?.target?.data?.flag && link?.target?.data?.currentNodes === nodeCount ? "" : link?.target?.data?.flag ? "flagVisible" : "hidden"} // Add CSS classes based on the visibility state}
        />
    );
};
export default Link;