import React from "react";
import Home   from "../../../../assets/images/home.png";


export default function App(){
    return(<div>
	    <nav className="top_breadcrumb">        
            <ol className="breadcrumb"> 
                <li className="breadcrumb-item"><a href="#"><img src={Home} alt="Your SVG" /></a></li>
                <li className="breadcrumb-item"><a href="#">NEWMIND</a></li> 
                <li className="breadcrumb-item"><a href="#">MUHAKEMAT ALEMI</a></li> 
                <li className="breadcrumb-item active" aria-current="page">ANALIZ</li>
            </ol>
        </nav>
    </div>);
} 
