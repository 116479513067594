import React, { useEffect, useRef } from "react";
import useState from "react-usestateref";
import * as d3 from "d3";
import Link from "./Link.js";
// import { Tooltip as ReactTooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import { trim } from "lodash";

var dataFlag = true;
var nodeCount = 10;
var parentNodeFlag = false;

const Dendrogram = ({
    dendrogram,
    width,
    height,
    countInfo,
    hoverNodeHide,
    totalCount,
}) => {
    const [graphValue, setGraphValue] = useState(dendrogram);
    const [childNodeHeights, setChildNodeHeights, childNodeHeightsRef] = useState(
        []
    );
    const [childANodeData, setChildNodeData] = useState([]);
    const [isParent, setIsParent] = useState(false);
    const [hoveredNode, setHoveredNode] = useState(hoverNodeHide);

    const chartGraphRef = useRef(null);

    const tree = d3.tree().size([height + 500, width]);

    const root = d3.hierarchy(
        dendrogram?.name ? dendrogram : [],
        (d) => d.children
    );
    tree(root);

    const nodes = root.descendants();

    nodes.forEach(function (d) {
        d.y = d.depth * 180;
    });

    useEffect(() => {
        console.log(graphValue, childANodeData, childNodeHeights);
        const tree = d3.tree().size([height + 500, width]);

        const root = d3.hierarchy(
            dendrogram?.name ? dendrogram : [],
            (d) => d.children
        );
        tree(root);
    }, []);

    useEffect(() => {
        if (hoveredNode !== null) {
            //console.log("hoveredNode", hoveredNode);
        }
    }, [hoveredNode]);

    // function to group nodes by their x-axis position
    function groupNodesByY(node, groups) {
        if (!groups) groups = {};
        if (!groups[node.x]) groups[node.x] = [];
        groups[node.x].push(node);
        if (node.children) {
            node.children.forEach((child) => {
                groupNodesByY(child, groups);
            });
        }
        return groups;
    }

    // function to group nodes by their y-axis position
    function groupNodesByX(node, groups) {
        if (!groups) groups = {};
        if (!node?.data?.depthFlag && node?.data?.flag) {
            if (!groups[node.y]) groups[node.y] = [];
            groups[node.y].push(node);
        }
        if (node.children) {
            node.children.forEach((child) => {
                groupNodesByX(child, groups);
            });
        }
        return groups;
    }

    // function to calculate the difference in x-axis position between the first and last nodes in each group
    function calculateChildNodeHeights(node, name) {
        const groups = groupNodesByY(node);
        const groupsY = groupNodesByX(node);
        const childHeights = [];
        const childWidths = [];
        const colorCode = [];
        //const childCustMizSize = [];

        Object.values(groups).forEach((values) => {
            let color = { color: values[0]?.data?.color, x: values[0]?.x };
            colorCode.push(color);
        });

        Object.keys(groups).forEach((x) => {
            childHeights.push(x);
        });

        Object.keys(groupsY).forEach((y) => {
            childWidths.push(y);
        });

        let color = colorCode.length
            ? colorCode[colorCode.length - 1]?.color
            : "#3f5373";

        const maxHeight = Math.max(...childHeights);
        const minHeight = Math.min(...childHeights);
        const height = maxHeight - minHeight;
        const maxWidth = Math.max(...childWidths);

        let nodeDetails = {
            name: name,
            height: height,
            minHeight: minHeight,
            maxHeight: maxHeight,
            colorCode: color,
            maxWidth: maxWidth,
            parentNodeValue: node?.data?.value,
        };
        return nodeDetails;
    }

    const diagonal = d3
        .linkHorizontal()
        .x((d) => d.y)
        .y((d) => d.x);

    useEffect(() => {
        dataFlag = true;
        const tree = d3.tree().size([height + 500, width]);
        const root = d3.hierarchy(graphValue, (d) => d.children);
        tree(root);

        // usage
        const childANode = root.children;
        childANode !== undefined && childANode?.length
            ? setChildNodeData(childANode)
            : [];

        setTimeout(() => {
            nodeCount = nodeCount + 1;
        }, 1000);
    }, [graphValue]);

    useEffect(() => {
        dataFlag = true;
    }, [childNodeHeightsRef.current]);

    function handleNodeClick(node) {
        setHoveredNode([]);
        if (node?.parent === null) {
            setIsParent(!isParent);
            parentNodeFlag = !parentNodeFlag;
        } else {
            setIsParent(false);
            parentNodeFlag = false;
        }
        // If node has children, hide them and add their links to hiddenLinks
        if (node.children) {
            let nodeFlag = false;
            let number = 0;
            nodeCount = nodeCount + 1;
            number = number + 1;
            const childLinks = root.links().filter((link) => link.source === node);
            let result = childLinks.map((link, index) => {
                if (index === 0) {
                    nodeFlag = link?.target?.data?.flag ? false : true;
                }
                link.target.data.flag = nodeFlag;
                link.target.data.currentNodes = nodeCount;
                link.target.data.depthChange = number;
                link.target.data.hoverMouse = nodeFlag;
                link.target.data.percentageValue = false;
                //let flagValue = link.target.data.flag;
                if (
                    link?.target?.data?.children &&
                    link?.target?.data?.children?.length > 0
                ) {
                    handleChildNode(
                        link?.target?.data?.children,
                        nodeFlag,
                        nodeCount,
                        number + 1
                    );
                }
                return link;
            });
            setGraphValue(result);
        }
    }

    function handleChildNode(node, flagValue, nodeCount, number) {
        // If node has children, hide them and add their links to hiddenLinks
        if (node?.length) {
            const childLinks = root.links().filter((link) => {
                return link.source?.data?.children === node;
            });

            let result = childLinks.map((link) => {
                link.target.data.flag = flagValue;
                link.target.data.currentNodes = nodeCount;
                link.target.data.depthChange = number;
                link.target.data.hoverMouse = flagValue ? true : false;
                link.target.data.percentageValue = false;
                if (
                    link?.target?.data?.children &&
                    link?.target?.data?.children?.length > 0
                ) {
                    handleChildNode(
                        link?.target?.data?.children,
                        flagValue,
                        nodeCount,
                        number + 1
                    );
                }
                return link;
            });
            return result;
        }
    }

    // This function is used to truncate the text of each node label to a certain length.
    const truncate = (str, length) => {
        return str.slice(0, length) + "...";
    };

    // function getNodeHeight(node) {
    //     return node.x;
    // }

    const radiusScale = d3
        .scaleLinear()
        .domain([countInfo?.minCount, countInfo?.maxCount])
        .range([4, 7]);

    const renderTime = (childANode) => {
        if (childANode !== undefined && childANode?.length) {
            const childHeights = childANode.map((child) => {
                const height = calculateChildNodeHeights(child, child?.data?.name);
                return height;
            });
            // setChildNodeHeights(prevArray => [...prevArray, childHeights]);
            setChildNodeHeights(childHeights);
            //console.log("childHeights", childHeights);
        }
    };

    const onMouseHoverLeave = (d) => {
        d.data.percentageValue = false;
    };

    const onHoveredNode = (e, d) => {
        let nodePopup = [];
        let count = 0;
        if (trim(d?.data?.name) !== "") {
            let currentPostion = e.target.getBoundingClientRect();
            let positionUpDown = "down";
            if (screen.height / 2 <= currentPostion?.y) {
                positionUpDown = "up";
            }
            d.data.valueX = d?.x;
            d.data.valueY = d?.y;

            let chartGraphRefHieght = 0;
            if (chartGraphRef.current) {
                if (chartGraphRef.current.getBoundingClientRect().y > 0) {
                    chartGraphRefHieght = chartGraphRef.current.getBoundingClientRect().y;
                }
            }
            let positionTop = d?.x - currentPostion?.y + chartGraphRefHieght;

            d.data.positionTop = positionTop;
            //d.data.hoverMouse = true;
            d.data.percentageValue = true;
            d.data.hoverMouse = d.data.flag;
            d.data.realDepth = d.depth;
            d.data.positionUpDown = positionUpDown;

            d?.data?.children?.length && parentToChildHover(d?.data?.children, true);
            nodePopup.push(d?.data);
            let lastNodeFlag = d?.data?.lastNode ? true : false;
            if (trim(d?.parent?.data?.name) !== "" && d?.parent !== null) {
                nodePopupDataPrepare(
                    d?.parent,
                    nodePopup,
                    count + 1,
                    d?.x,
                    d?.y,
                    positionUpDown,
                    positionTop,
                    lastNodeFlag
                );
            }

            const uniqueNodes = nodePopup.reduce((node, current) => {
                if (!node.find((item) => item.id === current.id)) {
                    node.push(current);
                }
                return node;
            }, []);
            const hierarchyArray = createHierarchy(uniqueNodes, 1);

            let totalHeight = hierarchyArray[0].lengthValue * 50;
            let screenSize = screen.height;

            if (totalHeight > screenSize / 2) {
                hierarchyArray[0].positionUpDown = "top";
            } else if (hierarchyArray[0].positionUpDown == "down") {
                hierarchyArray[0].positionTop = hierarchyArray[0].valueX;
            } else {
                hierarchyArray[0].positionTop =
                    hierarchyArray[0].valueX - hierarchyArray[0].lengthValue * 50;
            }

            let rightPositionFor = hierarchyArray[0].valueY + 400 + 150;
            if (rightPositionFor > width + 200) {
                hierarchyArray[0].valueY = hierarchyArray[0].valueY - 300;
                hierarchyArray[0].positionTop = hierarchyArray[0].positionTop + 20;
            } else {
                hierarchyArray[0].valueY = hierarchyArray[0].valueY + 150;
            }

            setHoveredNode(hierarchyArray);
        } else {
            setHoveredNode([]);
        }
    };

    const nodePopupDataPrepare = (
        parentData,
        nodePopup,
        count,
        x,
        y,
        positionUpDown,
        positionTop,
        lastNodeFlag
    ) => {
        if (trim(parentData?.data?.name) !== "") {
            parentData.data.valueX = x;
            parentData.data.valueY = y;
            parentData.data.realDepth = parentData?.depth;
            parentData.data.hoverMouse = parentData.data.flag;
            parentData.data.positionUpDown = positionUpDown;
            parentData.data.positionTop = positionTop;
            nodePopup.unshift(parentData?.data);

            if (parentData?.children?.length && count === 1 && lastNodeFlag) {
                parentData?.children?.length &&
                    parentData?.children.map((child) => {
                        child.data.valueX = x;
                        child.data.valueY = y;
                        child.data.realDepth = child?.depth;
                        child.data.hoverMouse = child.data.flag;
                        child.data.positionUpDown = positionUpDown;
                        child.data.positionTop = positionTop;
                        nodePopup.push(child?.data);
                    });
            }

            if (trim(parentData?.parent?.data?.name) !== "") {
                nodePopupDataPrepare(
                    parentData?.parent,
                    nodePopup,
                    count + 1,
                    x,
                    y,
                    positionUpDown,
                    positionTop
                );
            }

            return nodePopup;
        }
    };

    function createHierarchy(data, parentId = null) {
        const result = [];
        for (const item of data) {
            item.lengthValue = data.length;
            if (item.realDepth === parentId) {
                const newItem = { ...item };
                newItem.children = createHierarchy(data, item.realDepth + 1);
                result.push(newItem);
            }
        }
        return result;
    }

    const parentToChildHover = (childData, flag) => {
        // If node has children, hover them and add their hover flag.
        if (childData?.length) {
            childData.map((link) => {
                link.hoverMouse = flag === false ? false : link.flag;
                if (link?.children && link?.children?.length > 0) {
                    parentToChildHover(link?.children, flag);
                }
            });
        }
    };

    const hoverChildNode = (node, parentValue, number) => {
        return node?.children?.length ? (
            <ul className={"sub_list"}>
                {node?.children?.length
                    ? node?.children?.map((child, i) => {
                        return (
                            <React.Fragment key={child?.id + "_" + i + number}>
                                <li className={node?.children[0]?.children?.length ? "blue" : node?.children?.length - 1 === i ? "blue last-node" : "blue"}>
                                    <div className="cnt">
                                        {node?.name} :{" "}
                                        <strong>
                                            {node?.value} ₺{" "}
                                            <span className="text_blue">
                                                {node?.percentageValue
                                                    ? "%" +
                                                    ((node?.value / parentValue) * 100).toFixed(2)
                                                    : null}
                                            </span>
                                        </strong>
                                    </div>
                                    {node?.children?.length && node?.children[0]?.children?.length
                                        ? hoverChildNode(node?.children[0], node?.value, number + 1)
                                        : hoverLastNode(node, node?.value)}
                                </li>

                            </React.Fragment>
                        );
                    })
                    : null}
            </ul>
        ) : null;
    };

    const hoverLastNode = (child, parentValue) => {
        return child?.children?.length ? (
            <React.Fragment>
                <ul className="sub_list">
                    {child?.children?.length
                        ? child?.children.map((subChild, j) => {
                            return (
                                <React.Fragment key={subChild?.id + "_" + j}>
                                    {j === 0 ? (
                                        <li className={child?.children[0]?.children?.length ? "blue" : child?.children?.length - 1 === j ? "blue last-node" : "blue"}>
                                            <div className="cnt">
                                                {subChild?.name}:{" "}
                                                <strong>
                                                    {subChild?.value} ₺{" "}
                                                    <span className="text_blue">
                                                        {subChild?.percentageValue
                                                            ? "%" +
                                                            ((subChild?.value / parentValue) * 100).toFixed(
                                                                2
                                                            )
                                                            : null}
                                                    </span>{" "}
                                                </strong>
                                            </div></li>
                                    ) : <li className={child?.children[0]?.children?.length ? "blue no-line" : child?.children?.length - 1 === j ? "blue no-line last-node" : "blue no-line"}> 
                                        {/* <li className={child?.children?.length - 1 === j ? "blue no-line last-node" : "blue no-line"}> */}
                                        <div className="cnt">
                                            {subChild?.name}:{" "}
                                            <strong>
                                                {subChild?.value} ₺{" "}
                                                <span className="text_blue">
                                                    {subChild?.percentageValue
                                                        ? "%" +
                                                        ((subChild?.value / parentValue) * 100).toFixed(
                                                            2
                                                        )
                                                        : null}
                                                </span>{" "}
                                            </strong>
                                        </div> </li>}

                                </React.Fragment>
                            );
                        })
                        : null
                    }
                </ul>
            </React.Fragment>
        ) : null;
    };

    return (
        <React.Fragment>
            <svg
                ref={chartGraphRef}
                width={width + 200}
                height={height + 500}
                id="dendrogram"
                onClick={() => setHoveredNode([])}
                onMouseEnter={() => setHoveredNode([])}
            >
                <g transform={"translate(50, 5)"}>
                    {root.descendants().map((d, i) => {
                        const isNodeHidden = d?.data?.flag ? false : true;
                        //const nodeSpacing = 10; // Adjust the spacing value as desired
                        if (i === 0 && dataFlag) {
                            dataFlag = false;
                            console.log(dataFlag);
                            // usage
                            const childANode = root.children;
                            childANode !== undefined && childANode?.length
                                ? setChildNodeData(childANode)
                                : [];
                            renderTime(childANode);
                        }
                        return (
                            <g
                                key={d.data.id}
                                transform={"translate(" + d.y + "," + d.x + ")"}
                            >
                                {/* <g key={d.data.id} transform={"translate("+d.y+","+ (d.x) +")"}> */}
                                <circle
                                    //r={d?.data?.flag ? 4 : 0}
                                    r={
                                        d?.data?.depthFlag
                                            ? 0
                                            : d?.data?.flag
                                                ? d?.data?.lastNode
                                                    ? radiusScale(d?.data?.value)
                                                    : 4
                                                : 0
                                    }
                                    fill={
                                        d?.data?.depthFlag
                                            ? ""
                                            : d?.data?.flag
                                                ? d?.data?.color
                                                : ""
                                    }
                                    //stroke={d?.data?.flag ? "black" : null}
                                    strokeWidth={d?.data?.depthFlag ? 0 : d?.data?.flag ? 1 : 0}
                                    onClick={() => handleNodeClick(d)}
                                    cursor="pointer"
                                    className={
                                        d?.data?.hoverMouse &&
                                            d?.data?.flag &&
                                            d?.data?.currentNodes === nodeCount &&
                                            d?.data?.depthChange
                                            ? d?.data?.depthChange === 1
                                                ? "visibleNT" + d?.data?.depthChange + " visibleNode"
                                                : "visibleNode visibleNT" + d?.data?.depthChange
                                            : !d?.data?.flag && d?.data?.currentNodes === nodeCount
                                                ? ""
                                                : d?.data?.flag
                                                    ? "flagVisible"
                                                    : "hidden"
                                    } // Add CSS classes based on the visibility state}
                                    onMouseLeave={() => setHoveredNode([])} // Reset the hoveredNode when mouse leaves
                                    onMouseEnter={() => setHoveredNode([])} // Reset the hoveredNode when mouse leaves
                                //className={d?.data?.hoverMouse && d?.data?.flag && d?.data?.currentNodes === nodeCount && d?.data?.depthChange ? "flagVisible visibleNode" : !d?.data?.flag && d?.data?.currentNodes === nodeCount ? "" : d?.data?.flag ? "flagVisible" : "hidden"} // Add CSS classes based on the visibility state}
                                />
                                <text
                                    x={d.data.flag && d.data.lastNode ? 10 : -25}
                                    //x={-25 + (d.depth * nodeSpacing)} // Add spacing based on node depth
                                    y={d.data.flag && d.data.lastNode ? 0 : -15}
                                    dy=".35em"
                                    textAnchor={d.data.flag && d.data.lastNode ? "start" : "top"}
                                    fontSize={isNodeHidden ? "12px" : "13px"}
                                    fill={isNodeHidden ? "gray" : "black"}
                                    className={
                                        d?.data?.value === 0
                                            ? "disabled"
                                            : d?.data?.hoverMouse &&
                                                d?.data?.flag &&
                                                d?.data?.currentNodes === nodeCount &&
                                                d?.data?.depthChange
                                                ? d?.data?.depthChange === 1
                                                    ? "visibleNT" + d?.data?.depthChange + " visibleText"
                                                    : "visibleText visibleNT" + d?.data?.depthChange
                                                : !d?.data?.flag && d?.data?.currentNodes === nodeCount
                                                    ? ""
                                                    : d?.data?.flag
                                                        ? "flagVisible"
                                                        : "hidden"
                                    } // Add CSS classes based on the visibility state}
                                    title={d?.data?.name}
                                    data-tip={d?.data?.name} // Set the tooltip content as a data attribute
                                    id={d?.data?.name + "-" + d?.data?.id}
                                    onMouseEnter={(e) => onHoveredNode(e, d)} // Set the hoveredNode when mouse enters
                                    onMouseLeave={() => { setHoveredNode([]), onMouseHoverLeave(d); }} // Reset the hoveredNode when mouse leaves

                                    //className={d?.data?.value === 0 ? "disabled" : d?.data?.hoverMouse && d?.data?.flag && d?.data?.currentNodes === nodeCount && d?.data?.depthChange ? "flagVisible visibleText" : !d?.data?.flag && d?.data?.currentNodes === nodeCount ? "" : d?.data?.flag ? "flagVisible" : "hidden"} // Add CSS classes based on the visibility state}
                                    //style={{ opacity: d?.data?.value === 0 ? .3 : d?.data?.flag ? 1 : 0, animation: d?.data?.value === 0 ? "none" : d?.data?.flag ? "fadeIn 0.9s ease-in" : "none" }}
                                >
                                    {d?.data?.depthFlag
                                        ? null
                                        : d?.data?.flag
                                            ? d?.data?.name.length > 20
                                                ? truncate(d?.data?.name, 20)
                                                : d?.data?.name
                                            : null}
                                </text>

                                {/* <text
                                    x={d.children ? -10 : -20}
                                    y={isNodeHidden ? 0 : 0}
                                    dy=".35em"
                                    textAnchor={d.children ? "end" : "start"}
                                    fontSize="10px"
                                    fill={isNodeHidden ? "gray" : "black"}
                                >
                                    {d?.data?.depthFlag ? null : d?.data?.flag ? getNodeHeight(d): null}
                                </text> */}
                            </g>
                        );
                    })}
                    {root.links().map((link) => {
                        const isLinkHidden = link?.target?.data?.flag ? false : true;
                        return (
                            <Link
                                key={`${link.source.data.id}-${link.target.data.id}`}
                                link={link}
                                pathFunc={diagonal}
                                strokeWidth={
                                    link?.target?.data?.depthFlag ? 0 : isLinkHidden ? 0 : 1
                                }
                                stroke={
                                    isLinkHidden
                                        ? "none"
                                        : link.target.data.color
                                            ? link.target.data.color
                                            : "gray"
                                }
                                nodeCount={nodeCount}
                            />
                        );
                    })}
                </g>
                {childNodeHeightsRef.current.length && !parentNodeFlag
                    ? childNodeHeightsRef.current.map((height, index) => {
                        let color = height?.colorCode;
                        const maxMaxWidth = childNodeHeightsRef.current.reduce(
                            (maxWidth, item) => {
                                if (item.maxWidth !== 0 && item.maxWidth > maxWidth) {
                                    return item.maxWidth;
                                }
                                return maxWidth;
                            },
                            0
                        );

                        return (
                            <foreignObject
                                key={height.maxHeight + "_" + index}
                                onMouseEnter={() => setHoveredNode([])} // Reset the hoveredNode when mouse leaves
                                onMouseLeave={() => setHoveredNode([])} // Reset the hoveredNode when mouse leaves
                                x={maxMaxWidth ? maxMaxWidth + 200 : width + 100}
                                y={height.minHeight - 5}
                                width="80"
                                height={
                                    height?.height !== 0 ? height?.height + 10 : height?.height
                                }
                            >
                                <div
                                    style={{
                                        height: "100%",
                                        width: "60px",
                                        backgroundColor: color ? color : "#3f5373",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                >
                                    <span style={{ color: "#fff", fontSize: "13px" }}>
                                        %{" "}
                                        {parseFloat(
                                            (height?.parentNodeValue / totalCount) * 100
                                        ).toFixed(2)}
                                    </span>
                                    {/* <span style={{ color: "black" }}>{maxMaxWidth}</span> */}
                                </div>
                            </foreignObject>
                        );
                    })
                    : null}

                {hoveredNode?.length ? (
                    <foreignObject
                        onMouseEnter={() => setHoveredNode([])} // Reset the hoveredNode when mouse leaves
                        onMouseLeave={() => setHoveredNode([])} // Reset the hoveredNode when mouse leaves
                        x={hoveredNode[0].valueY}
                        y={hoveredNode[0].positionTop}
                        width={400}
                        height={hoveredNode[0].lengthValue === 1 ? 100 : hoveredNode[0].lengthValue === 2 ? 150 : hoveredNode[0].lengthValue === 3 ? 200 : hoveredNode[0].lengthValue * 50}>
                        {hoveredNode?.length
                            ? hoveredNode?.map((node, index) => {
                                return (
                                    <div key={node?.id + "_" + index} className="popupNode" style={{ left: 0, right: 0, top: 0 }}>
                                        <ul className="main">
                                            <li className={node?.children?.length ? "" : "last-node"}>
                                                <div className="cnt">
                                                    {node?.name} :{" "}
                                                    <strong className="text_blue">
                                                        {node?.value} ₺
                                                        {node?.percentageValue
                                                            ? "%" +
                                                            ((node?.value / node?.value) * 100).toFixed(2)
                                                            : null}
                                                    </strong>
                                                </div>
                                                {node?.children?.length ? (
                                                    <ul className="sub_list">
                                                        {node?.children?.length
                                                            ? node?.children?.map((child, i) => {
                                                                return (
                                                                    <React.Fragment key={child?.id + "_" + index + i}>
                                                                        <li className={node?.children[0]?.children?.length ? "light-blue" : node?.children?.length - 1 === i ? "light-blue last-node" : "light-blue"}>
                                                                            <div className="cnt">
                                                                                {child?.name} :{" "}
                                                                                <strong>{child?.value} ₺{" "}
                                                                                    <span className="text_blue">{child?.percentageValue
                                                                                        ? "%" +
                                                                                        (
                                                                                            (child?.value /
                                                                                                node?.value) *
                                                                                            100
                                                                                        ).toFixed(2)
                                                                                        : null}
                                                                                    </span>
                                                                                </strong>
                                                                            </div>
                                                                            {child?.children?.length &&
                                                                                child?.children[0]?.children?.length
                                                                                ? hoverChildNode(
                                                                                    child?.children[0],
                                                                                    child?.value, 1
                                                                                )
                                                                                : hoverLastNode(child, child?.value)
                                                                            }
                                                                        </li>
                                                                    </React.Fragment>
                                                                );
                                                            })
                                                            :
                                                            null}
                                                    </ul>
                                                ) : null}
                                            </li>
                                        </ul>
                                    </div>
                                );
                            })
                            :
                            null}
                    </foreignObject>
                ) : null}

                {/* y={height.minHeight}  */}
            </svg>
        </React.Fragment>
    );
};

export default Dendrogram;
