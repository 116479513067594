import React, { useEffect,useRef } from "react";
import useState from "react-usestateref";
import dendrogramIslahMaliRiskData from "./dendrogramIslahMaliRiskData.json";
import dendrogramMaliRiskData from "./dendrogramMaliRiskData.json";
import BreadCrumb from "./BreadCrumb";
import Form from "react-bootstrap/Form";
import Dendrogram from "./Dendrogram.js";
// import Filter from "../../../../assets/images/fil.svg";


function Index() {
    const [data, setData] = useState({});
    const [refresh, setRefresh] = useState(false);
    const [countInfo, setCountInfo] = useState({});
    const [ nodeDepth, setNodeDepth ] = useState([]);
    const [type, setType] = useState("mali_risk");
    const [ hoveredNode, setHoveredNode ] = useState([]);

    const  chartRef =  useRef(null);

    // get dendrogramcart list data
    const getDendrogramList = (datas) => {
        // usage
        const childANode = datas.children;
        const childDepths = childANode.map((child) => {
            const height = calculateDepthNode(child,child?.name);
            return height;
        });
        
        console.log("childDepths",childDepths);
        setNodeDepth(prevArray => [...prevArray, childDepths]);
        
        //addNodeFlagData(datas);
        //setData(datas);
        setRefresh(true); //called prepare dendrogramcart data    
    };

    //useEffect
    useEffect(() => {
        console.log(refresh);
        console.log(data);
        // console.log("window.innerWidth",window.innerWidth);
        // console.log("window.innerHeight",window.innerHeight); 
        getDendrogramList(type === "mali_rist" ? dendrogramMaliRiskData : dendrogramIslahMaliRiskData);
    }, []);

    //useEffect
    useEffect(() => {
        getDendrogramList(type === "mali_rist" ? dendrogramMaliRiskData : dendrogramIslahMaliRiskData);
    }, [type]);

    //useEffect
    useEffect(() => {
        
        if (chartRef.current) {
            const height = chartRef.current.clientHeight;
            console.log("Div height:", height);
        }

        // Find maximum depth count value in unique array
        const maxDepthObj = nodeDepth.length ? nodeDepth[0].reduce((acc, curr) => {
            return curr.count > acc.count ? curr : acc;
        }):{};
        let countArray = [];
        let count = 0;
        let prepareGraphData = type === "mali_risk" ? dendrogramMaliRiskData : dendrogramIslahMaliRiskData;
        let result = maxDepthObj?.name !=undefined && prepareGraphData?.children ? prepareGraphData?.children.map((link,index) => {
            
            link.flag = true;
            link.currentNodes = 0;
            link.depthChange = 0;
            count+=link.value;
            countArray.push(link?.value);

            // add last node flag
            if(link.children === undefined){
                link.lastNode = true; 
            }

            if(link?.name === maxDepthObj?.name){
                handleChildNode(link?.children,countArray);
                return link;
            }else if (link?.name !== maxDepthObj?.name && link?.children && link?.children?.length > 0) {
                let currentDepth = 1;
                handleDepthNode(link,link?.children,index,maxDepthObj?.maxDepth,currentDepth,countArray);
                //setData(resultNew);
            } else{
                for (let i = 0; i < maxDepthObj?.maxDepth; i++) {
                    let obj = {
                        color:link?.color,
                        flag:false,
                        currentNodes:0,
                        depthChange:0,
                        id:Math.floor((Math.random() * 1000000) + 1),
                        lastNode:true,
                        name:link?.name,
                        value:link?.value,
                        depthFlag:true
                    };
                    if(i == 0){
                        link.children = [];
                        link.children.push(obj);
                    } else if(i == 1){
                        link.children[0].children = [];
                        link.children[0].children.push(obj);
                    } else if(i == 2) {
                        link.children[0].children[0].children = [];
                        link.children[0].children[0].children.push(obj);
                    } else {
                        link.children[0].children[0].children[0].children = [];
                        link.children[0].children[0].children[0].children.push(obj);
                    }
                }
            }
            return link;
        }):[];

        setRefresh(true);
        result.name = prepareGraphData?.name;
        result.flag = true;
        result.currentNodes = 0;
        result.depthChange = 0;
        result.id = prepareGraphData?.id;
        result.count = count;

        prepareGraphData.flag = true;
        prepareGraphData.count = count;
        prepareGraphData.currentNodes = 0;
        prepareGraphData.depthChange = 0;
        
        let res = countArray.length ? countArray.filter((element) => {
            return (element !== undefined && element != null && element !=="");
        }) : [];

        const maxCount  = Math.max(...res);
        const minCount = Math.min(...res);
        const sumCount = res.reduce((partialSum, a) => partialSum + a, 0);
        const countLength = res.length;
        let countObj = {
            maxCount:maxCount,
            minCount:minCount,
            sumCount:sumCount,
            countLength:countLength

        };
        setCountInfo(countObj);
        setData(result);
    }, [nodeDepth]);
    
    const width = 900;
    const height = 700;

    const handleChildNode = (node,countArray) => {
        // If node has children, hide them and add their links to hiddenLinks
        if (node?.length) {
            let result = node.map((link) => {
                link.flag = true;
                link.currentNodes = 0;
                link.depthChange = 0;
                countArray.push(link.value);
                
                if(link?.children === undefined){
                    link.lastNode = true; 
                }

                if (link?.children && link?.children?.length > 0) {
                    handleChildNode(link?.children,countArray); 
                }   
                return link;
            });
            return result;  
        }
    };

    // add function for find maxDepth of children arrays to nodes
    function findMaxDepth(obj) {
        if (!obj.children || !obj.children.length) {
            return 0;
        } else {
            let maxDepth = 0;
            obj.children.forEach(child => {
                const depth = findMaxDepth(child);
                if (depth > maxDepth) {
                    maxDepth = depth;
                }
            });
            return maxDepth+1;
        }
    } 

    const calculateDepthNode = (node,name) => {
        let maxDepth = findMaxDepth(node);
        let depthObj = {
            name : name,
            maxDepth:maxDepth
        };
        return depthObj;
    };

    function handleDepthNode(link,linkChildren,index,maxDepth,currentDepth,countArray) {
        
        currentDepth = currentDepth + 1;
        maxDepth = maxDepth === 0 ? 0 : maxDepth-1;

        if (linkChildren[0]?.children && linkChildren[0]?.children?.length > 0) {
            linkChildren.length ? linkChildren.map((child) => {
                // add last node flag
                if(child.children === undefined){
                    child.lastNode = true;
                }
                child.flag = true;
                child.currentNodes = 0;
                child.depthChange = 0;
                countArray.push(child.value);
                handleDepthNode(child,child?.children,index,maxDepth,currentDepth,countArray);
                //setData(resultNew);
            }):[];
        } else {
            
            linkChildren.length ? linkChildren.map((child) => {
                if(child.children === undefined){
                    child.lastNode = true;
                }
                child.flag = true;
                child.currentNodes = 0;
                child.depthChange = 0;
                countArray.push(child.value);
                for (let i = 0; i < maxDepth; i++) {
                    let obj = {
                        color:child?.color,
                        flag:false,
                        currentNodes : 0,
                        depthChange :0,
                        id:Math.floor((Math.random() * 100000) + 1),
                        lastNode:true,
                        name:child?.name,
                        value:child?.value,
                        depthFlag:true
                    };
                    if(i == 0){
                        child.children = [];
                        child.children.push(obj);
                    } else if(i == 1){
                        child.children[0].children = [];
                        child.children[0].children.push(obj);
                    } else if(i == 2) {
                        child.children[0].children[0].children = [];
                        child.children[0].children[0].children.push(obj);
                    } else {
                        child.children[0].children[0].children[0].children = [];
                        child.children[0].children[0].children[0].children.push(obj);
                    }
                }
            }):[];
            
        }
        return linkChildren;
    }

    const onOptionChange = e => {
        console.log(e.target.value);
        setType(e.target.value);
        //setType("mali_risk");
    };

    return (

        <div className="dendrogramcart" onClick={() => setHoveredNode([])}>
            {/*LeftChart*/}
            <BreadCrumb />
            <div></div>

            <div className="content">
                {/*top section*/}
                <div className="d-flex justify-content-between p-3 align-items-center">
                    {/* <div className="d-flex align-items-center radio-button">
                        <div>
                            <input type="radio" id="test1" name="type" value="mali_risk" checked={type === "mali_risk"} onChange={(e)=>onOptionChange(e)}/>  <label>Mali Risk </label>
                        </div>
                            
                        <div className="ms-3">
                            <input type="radio" id="type" name="type" value="muhtemal_islah_mali_risk" checked={type === "muhtemal_islah_mali_risk"} onChange={(e)=>onOptionChange(e)}/><label> Muhtemal Islah Mali Risk</label>
                        </div>
                    </div> */}

                    <div className="d-flex justify-content-between p-3 radio_btn">
                        <Form.Check
                            label="Mali Risk"
                            name="group1"
                            type="radio"
                            value="mali_risk"
                            id={"ab1"}
                            className="me-3"
                            checked={type === "mali_risk"} 
                            onChange={(e)=>onOptionChange(e)}
                        />
                        <Form.Check
                            label="Muhtemal Islah Mali Risk"
                            name="group1"
                            type="radio"
                            value="muhtemal_islah_mali_risk"
                            id={"ab2"}
                            checked={type === "muhtemal_islah_mali_risk"} onChange={(e)=>onOptionChange(e)}
                        />
                    </div>
                    {/* <button className="filter-btn">
                        <img src={Filter} alt="filter" height={"25px"} width={"25px"}/>  Filters
                    </button> */}
                </div>    


                {/*top section*/}

                {/*Chart section*/}
                <div className="p-relative d-flex overflow-auto" style={{backgroundColor:"#fcfcff"}}>
                    <div className="">
                        <div className="chart_content d-flex align-items-center">
                           
                            <div className="content_graph" ref={chartRef}>
                                <div className={type === "mali_risk" ? "ms-4 total-cr_2" : "ms-4 total-cr"}>
                                    <div className="line-vr">
                                        <h4 style={{color:"#27239e",fontWeight:"bold"}}>12.178,76 ₺</h4> 
                                        <h5 style={{color:"#cae0d7", fontWeight:"600"}}>2021/26447</h5>
                                    </div> 
                                </div>
                                <Dendrogram data={data} height={height} width={width} dendrogram={type === "mali_risk" ? dendrogramMaliRiskData : dendrogramIslahMaliRiskData} countInfo={countInfo} totalCount={1217876} hoverNodeHide={hoveredNode}/>
                            </div>
                        </div>
                    </div>               
                </div>
                {/*Chart section*/}
             
            </div>
        </div>
    );
}

export default Index;
